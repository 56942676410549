import React from 'react';

const OutsourcingOutsourcing2 = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7097 19.5505C16.6007 19.5505 14.3523 15.4145 14.3523 12.6386C14.2992 11.9007 14.3987 11.1601 14.6446 10.4624C14.8905 9.76475 15.2775 9.12502 15.7815 8.58349C16.2854 8.04196 16.8956 7.61013 17.5738 7.31482C18.2521 7.0195 18.9839 6.86719 19.7236 6.86719C20.4634 6.86719 21.1952 7.0195 21.8735 7.31482C22.5517 7.61013 23.1619 8.04196 23.6659 8.58349C24.1698 9.12502 24.5568 9.76475 24.8027 10.4624C25.0486 11.1601 25.1481 11.9007 25.095 12.6386C25.095 15.4145 22.8187 19.5505 19.7097 19.5505ZM19.7097 8.64143C18.6544 8.64876 17.6449 9.07316 16.9012 9.82201C16.1576 10.5709 15.7402 11.5832 15.7402 12.6386C15.7402 14.9981 17.7111 18.1904 19.7097 18.1904C21.7084 18.1904 23.707 15.0258 23.707 12.6386C23.6998 11.5807 23.2763 10.5684 22.5282 9.82031C21.7802 9.07224 20.7676 8.6487 19.7097 8.64143Z"
      fill="#475467"
    />
    <path
      d="M25.395 37.3915C25.2109 37.3915 25.0344 37.3184 24.9042 37.1882C24.7741 37.0581 24.701 36.8816 24.701 36.6976C24.701 36.5135 24.7741 36.337 24.9042 36.2069C25.0344 36.0767 25.2109 36.0036 25.395 36.0036C26.1312 36.0036 26.8372 35.7112 27.3578 35.1907C27.8784 34.6701 28.1709 33.9639 28.1709 33.2277V26.2879C28.1734 24.8714 27.6344 23.5076 26.6643 22.4755C25.6941 21.4434 24.3661 20.8211 22.9522 20.7361L20.1763 24.2893C20.1088 24.3704 20.0238 24.4351 19.9276 24.4784C19.8314 24.5217 19.7265 24.5424 19.6211 24.5391C19.5193 24.5448 19.4177 24.5248 19.3256 24.4811C19.2335 24.4375 19.1537 24.3717 19.0936 24.2893L16.3177 20.7361C14.9038 20.8211 13.5758 21.4434 12.6057 22.4755C11.6355 23.5076 11.0966 24.8714 11.0991 26.2879V33.0335C11.0991 33.7697 11.3915 34.4756 11.9121 34.9962C12.4327 35.5167 13.1388 35.8094 13.875 35.8094C14.0591 35.8094 14.2355 35.8822 14.3657 36.0124C14.4958 36.1425 14.569 36.3193 14.569 36.5034C14.569 36.6874 14.4958 36.8639 14.3657 36.994C14.2355 37.1242 14.0591 37.1974 13.875 37.1974C12.746 37.1974 11.6628 36.7508 10.8619 35.9551C10.061 35.1594 9.60743 34.0791 9.6001 32.9502V26.2879C9.60744 24.4522 10.3418 22.694 11.6425 21.3986C12.9431 20.1031 14.7041 19.3759 16.5399 19.3759C16.6416 19.3703 16.7432 19.3903 16.8353 19.4339C16.9275 19.4775 17.0072 19.5433 17.0673 19.6257L19.5378 22.7625L22.0084 19.6257C22.0729 19.5482 22.1535 19.4858 22.2446 19.4427C22.3356 19.3996 22.435 19.3768 22.5358 19.3759C24.3716 19.3759 26.1325 20.1031 27.4331 21.3986C28.7338 22.694 29.4682 24.4522 29.4756 26.2879V33.0335C29.4984 34.1487 29.0845 35.2286 28.3221 36.0429C27.5597 36.8571 26.5093 37.3411 25.395 37.3915Z"
      fill="#475467"
    />
    <path
      d="M14.0465 48.2665C13.8625 48.2665 13.6859 48.1934 13.5558 48.0632C13.4256 47.9331 13.3525 47.7566 13.3525 47.5725V26.9752C13.3525 26.7912 13.4256 26.6147 13.5558 26.4846C13.6859 26.3544 13.8625 26.2812 14.0465 26.2812C14.2306 26.2812 14.407 26.3544 14.5372 26.4846C14.6673 26.6147 14.7405 26.7912 14.7405 26.9752V47.5725C14.7405 47.7566 14.6673 47.9331 14.5372 48.0632C14.407 48.1934 14.2306 48.2665 14.0465 48.2665Z"
      fill="#475467"
    />
    <path
      d="M25.3983 48.2665C25.2143 48.2665 25.0377 48.1934 24.9076 48.0632C24.7774 47.9331 24.7043 47.7566 24.7043 47.5725V26.9752C24.7043 26.7912 24.7774 26.6147 24.9076 26.4846C25.0377 26.3544 25.2143 26.2812 25.3983 26.2812C25.5824 26.2812 25.7588 26.3544 25.889 26.4846C26.0191 26.6147 26.0923 26.7912 26.0923 26.9752V47.5725C26.0923 47.7566 26.0191 47.9331 25.889 48.0632C25.7588 48.1934 25.5824 48.2665 25.3983 48.2665Z"
      fill="#475467"
    />
    <path
      d="M19.7057 48.2701C19.5216 48.2701 19.3452 48.1969 19.215 48.0668C19.0849 47.9366 19.0117 47.7602 19.0117 47.5761V38.2213C19.0117 38.0373 19.0849 37.8605 19.215 37.7303C19.3452 37.6002 19.5216 37.5273 19.7057 37.5273C19.8897 37.5273 20.0663 37.6002 20.1964 37.7303C20.3266 37.8605 20.3997 38.0373 20.3997 38.2213V47.5761C20.3997 47.7602 20.3266 47.9366 20.1964 48.0668C20.0663 48.1969 19.8897 48.2701 19.7057 48.2701Z"
      fill="#475467"
    />
    <path
      d="M28.1384 11.9386C25.0294 11.9386 22.7532 7.80246 22.7532 4.99879C22.8509 3.64093 23.4592 2.37064 24.4558 1.44318C25.4523 0.515727 26.7632 0 28.1245 0C29.4859 0 30.7968 0.515727 31.7933 1.44318C32.7899 2.37064 33.3982 3.64093 33.4959 4.99879C33.4959 7.80246 31.2474 11.9386 28.1384 11.9386ZM28.1384 1.02944C27.0831 1.02942 26.0705 1.44671 25.3217 2.19036C24.5729 2.93401 24.1485 3.94346 24.1411 4.99879C24.1411 7.38607 26.112 10.5506 28.1384 10.5506C30.1648 10.5506 32.108 7.38607 32.108 4.99879C32.108 3.94601 31.6898 2.93649 30.9453 2.19205C30.2009 1.44762 29.1912 1.02944 28.1384 1.02944Z"
      fill="#475467"
    />
    <path
      d="M33.81 29.7646C33.6282 29.7577 33.4556 29.6825 33.3269 29.5538C33.1982 29.4251 33.1229 29.2525 33.1161 29.0706C33.1161 28.8866 33.1891 28.7101 33.3193 28.58C33.4494 28.4498 33.626 28.3766 33.81 28.3766C34.5462 28.3766 35.2523 28.0843 35.7728 27.5637C36.2934 27.0431 36.5859 26.3369 36.5859 25.6007V18.8829C36.5885 17.4664 36.0495 16.1026 35.0793 15.0705C34.1091 14.0384 32.7812 13.4161 31.3672 13.3311L28.5913 16.8844C28.4513 17.0018 28.2744 17.0663 28.0917 17.0663C27.9089 17.0663 27.732 17.0018 27.592 16.8844C27.4711 16.7515 27.4041 16.5785 27.4041 16.3988C27.4041 16.2191 27.4711 16.0457 27.592 15.9129L30.6178 12.0821C30.6795 12.0015 30.7595 11.9368 30.8512 11.8934C30.943 11.8499 31.0437 11.8291 31.1452 11.8324C32.0552 11.8287 32.957 12.0048 33.7988 12.3505C34.6406 12.6962 35.4058 13.2047 36.0506 13.8469C36.6954 14.4891 37.2071 15.2524 37.5562 16.0928C37.9053 16.9332 38.0849 17.8343 38.0849 18.7443V25.4618C38.0886 26.0255 37.9807 26.5844 37.7675 27.1063C37.5543 27.6281 37.24 28.1028 36.8427 28.5027C36.4454 28.9026 35.9728 29.22 35.4524 29.4366C34.9319 29.6531 34.3738 29.7646 33.81 29.7646Z"
      fill="#475467"
    />
    <path
      d="M33.8019 40.665C33.6178 40.665 33.4413 40.5918 33.3111 40.4616C33.181 40.3315 33.1079 40.155 33.1079 39.971V19.3737C33.1079 19.1896 33.181 19.0131 33.3111 18.883C33.4413 18.7529 33.6178 18.6797 33.8019 18.6797C33.9859 18.6797 34.1624 18.7529 34.2925 18.883C34.4227 19.0131 34.4959 19.1896 34.4959 19.3737V39.971C34.4959 40.155 34.4227 40.3315 34.2925 40.4616C34.1624 40.5918 33.9859 40.665 33.8019 40.665Z"
      fill="#475467"
    />
  </svg>
);

export default OutsourcingOutsourcing2;
